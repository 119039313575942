export default function tableColumns() {
  const oldColumns = [
    {
      thClass: 'text-center',
      label: 'Nomor Surat Permohonan',
      field: 'letter_number',
      filterOptions: {
        enabled: true,
        placeholder: 'Nomor Surat',
      },
    },
    {
      thClass: 'text-center',
      label: 'Tanggal Surat Rekomendasi',
      field: 'tanggal_surat',
    },
    {
      thClass: 'text-center',
      label: 'Surat Rekomendasi Kop Kominfo',
      field: 'no_surat_kominfo',
      sortable: false,
      filterOptions: {
        enabled: true,
        placeholder: 'Nomor Surat',
      },
    },
    {
      thClass: 'text-center',
      label: 'Surat Rekomendasi Kop MenpanRB',
      field: 'no_surat_panrb',
      sortable: false,
      filterOptions: {
        enabled: true,
        placeholder: 'Nomor Surat',
      },
    },
    {
      thClass: 'text-center',
      label: 'Daftar Aplikasi / Infrastruktur',
      field: 'surat_id',
      sortable: false,
    },
  ]

  const newColumns = [
    {
      thClass: 'text-center',
      label: 'Nomor Surat Permohonan',
      field: 'letter_number',
      filterOptions: {
        enabled: true,
        placeholder: 'Nomor Surat',
      },
    },
    {
      thClass: 'text-center',
      label: 'Tanggal Surat Rekomendasi',
      field: 'tanggal_surat',
    },
    {
      thClass: 'text-center',
      label: 'Surat Rekomendasi Kop Kominfo',
      field: 'no_surat_kominfo',
      sortable: false,
      filterOptions: {
        enabled: true,
        placeholder: 'Nomor Surat',
      },
    },
    {
      thClass: 'text-center',
      label: 'Surat Rekomendasi Kop MenpanRB',
      field: 'no_surat_panrb',
      sortable: false,
      filterOptions: {
        enabled: true,
        placeholder: 'Nomor Surat',
      },
    },
    {
      thClass: 'text-center',
      label: 'Surat Rekomendasi Kop Bappenas',
      field: 'no_surat_bapenas',
      sortable: false,
      filterOptions: {
        enabled: true,
        placeholder: 'Nomor Surat',
      },
    },
    {
      thClass: 'text-center',
      label: 'Daftar Item Belanja',
      field: 'surat_id',
      sortable: false,
    },
  ]

  return {
    oldColumns,
    newColumns,
  }
}
